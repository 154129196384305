import styled from "styled-components";
import { Color } from "../../../layout/GlobalStyles/Color";
import { Font } from "../../../layout/GlobalStyles/Font";

export const SignupFormContainer = styled.div`
    background: ${Color.White10};
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    backdrop-filter: blur(8px);
    display: grid;
    grid-row-gap: 32px;
    box-sizing: border-box;
    width: 300px;
    padding: 32px;
    border-radius: 4px;
    text-align: center;
`;

export const Heading = styled.h1`
    font-family: ${Font.SpaceGrotesk};
    font-size: 24px;
    line-height: 120%;
    font-weight: 700;
`;
